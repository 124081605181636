<template>
  <v-container>
    <v-subheader class="title">Launches</v-subheader>

    <v-list subheader two-line>
      <div v-for="(launch, i) in launches" :key="launch.id">
        <div v-if="startNewMonth(i)">
          <v-subheader
            class="subtitle-2 mt-6"
            v-text="
              $moment(launch.net)
                .format('MMMM yyyy')
                .toUpperCase()
            "
          ></v-subheader>
        </div>

        <v-list-item>
          <v-list-item-avatar>
            <v-avatar
              class="headline"
              v-text="$moment(launch.net).format('D')"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="launch.name"></v-list-item-title>

            <v-list-item-subtitle
              v-if="launch.mission"
              v-text="launch.mission.description"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
            <v-list-item-action-text
              v-text="
                $moment(launch.net)
                  .format('HH:mm')
                  .toLowerCase()
              "
            ></v-list-item-action-text>
            <v-list-item-action-text
              v-text="launch.location"
            ></v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['launches'])
  },
  created() {
    this.$store.dispatch('fetchLaunches');
  },
  methods: {
    startNewMonth(i) {
      return (
        i == 0 ||
        this.$moment(this.launches[i].date).month() !=
          this.$moment(this.launches[i - 1].date).month()
      );
    }
  }
};
</script>
