var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-subheader',{staticClass:"title"},[_vm._v("Launches")]),_c('v-list',{attrs:{"subheader":"","two-line":""}},_vm._l((_vm.launches),function(launch,i){return _c('div',{key:launch.id},[(_vm.startNewMonth(i))?_c('div',[_c('v-subheader',{staticClass:"subtitle-2 mt-6",domProps:{"textContent":_vm._s(
            _vm.$moment(launch.net)
              .format('MMMM yyyy')
              .toUpperCase()
          )}})],1):_vm._e(),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"headline",domProps:{"textContent":_vm._s(_vm.$moment(launch.net).format('D'))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(launch.name)}}),(launch.mission)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(launch.mission.description)}}):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(
              _vm.$moment(launch.net)
                .format('HH:mm')
                .toLowerCase()
            )}}),_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(launch.location)}})],1):_vm._e()],1)],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }